
import { defineComponent, onMounted, ref, watch } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { useRoute, useRouter } from "vue-router";
import { useI18n } from "vue-i18n/index";
import { I18nUtils } from "@/sea/utils/I18nUtils";
import { getProductsRequest } from "@/sea/apis/ProductsService";
import { UserUtils } from "@/sea/utils/UserUtils";
import { DateTimeUtils } from "@/sea/utils/DateTimeUtils";
import Datatable from "@/sea/components/datatables/Datatable.vue";
import { getBranchesRequest } from "@/sea/apis/BranchesService";
import { getProductCategoriesRequest } from "@/sea/apis/ProductCategoriesService";
import { StringUtils } from "@/sea/utils/StringUtils";
import { reinitializeComponents } from "@/core/plugins/keenthemes";

export default defineComponent({
  name: "products",
  components: {
    Datatable,
  },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const { locale } = useI18n();

    const name = ref<string | null>(null);
    const branchId = ref(0);
    const productCategoryId = ref(0);
    const isDelivery = ref<boolean | null>(null);
    const isEnabled = ref<boolean | null>(null);
    const sort = ref<string | null>(null);
    const pageNumber = ref(1);
    const pageSize = ref(20);

    name.value = route.query.name ? `${route.query.name}` : null;
    branchId.value = Number(route.query.branchId) || 0;
    productCategoryId.value = Number(route.query.categoryId) || 0;
    isDelivery.value =
      StringUtils.toBoolean(`${route.query.isDelivery}`) ?? null;
    isEnabled.value = StringUtils.toBoolean(`${route.query.isEnabled}`) ?? null;
    pageNumber.value = Number(route.query.pageNumber) || 1;
    pageSize.value = Number(route.query.pageSize) || 10;

    const loading = ref(false);

    const generateTableHeaders = () => {
      return [
        {
          name: "Id",
          key: "id",
          sortable: true,
        },
        {
          name: I18nUtils.translateGlobal("sea.pages.products.tblHeaderName"),
          key: "name",
          sortable: true,
        },
        {
          name: I18nUtils.translateGlobal(
            "sea.pages.products.tblHeaderCategory"
          ),
          key: "category",
          sortable: true,
        },
        {
          name: I18nUtils.translateGlobal("sea.pages.products.tblHeaderPrice"),
          key: "price",
        },
        {
          name: I18nUtils.translateGlobal("sea.pages.products.tblHeaderBranch"),
          key: "branch",
          sortable: true,
        },
        {
          name: I18nUtils.translateGlobal(
            "sea.pages.products.tblHeaderDelivery"
          ),
          key: "delivery",
        },
        {
          name: I18nUtils.translateGlobal(
            "sea.pages.products.tblHeaderEnabled"
          ),
          key: "enabled",
        },
        {
          name: I18nUtils.translateGlobal("sea.pages.products.tblHeaderDate"),
          key: "date",
        },
        {
          name: I18nUtils.translateGlobal(
            "sea.pages.products.tblHeaderActions"
          ),
          key: "actions",
        },
      ];
    };

    const tableHeaders = ref(generateTableHeaders());

    watch(locale, () => {
      tableHeaders.value = generateTableHeaders();
    });

    const {
      productsRef,
      totalRef: productsTotalRef,
      pageNumberRef: productsPageNumberRef,
      pageSizeRef: productsPageSizeRef,
      getProducts,
    } = getProductsRequest();

    const onIsDeliveryChange = async (event) => {
      isDelivery.value = StringUtils.toBoolean(event.target.value);
      await getData(false);
    };

    const onIsEnabledChange = async (event) => {
      isEnabled.value = StringUtils.toBoolean(event.target.value);
      await getData(false);
    };

    const updateQueries = async () => {
      const newQuery = Object.assign({}, route.query);
      if (name.value) {
        newQuery.name = name.value;
      } else {
        delete newQuery.name;
      }
      if (branchId.value > 0) {
        newQuery.branchId = branchId.value.toString();
      } else {
        delete newQuery.branchId;
      }
      if (productCategoryId.value > 0) {
        newQuery.productCategoryId = productCategoryId.value.toString();
      } else {
        delete newQuery.productCategoryId;
      }
      if (isDelivery.value === true || isDelivery.value === false) {
        newQuery.isDelivery = isDelivery.value.toString();
      } else {
        delete newQuery.isDelivery;
      }
      if (isEnabled.value === true || isEnabled.value === false) {
        newQuery.isEnabled = isEnabled.value.toString();
      } else {
        delete newQuery.isEnabled;
      }
      if (pageNumber.value) newQuery.pageNumber = pageNumber.value.toString();
      if (pageSize.value) newQuery.pageSize = pageSize.value.toString();
      await router.replace({ query: newQuery });
    };

    const { branchesRef, getBranches } = getBranchesRequest();

    const onBranchChange = async (event) => {
      branchId.value = Number(event.target.value) || 0;
      await getData(false);
    };

    const { productCategoriesRef, getProductCategories } =
      getProductCategoriesRequest();

    const onProductCategoryChange = async (event) => {
      productCategoryId.value = Number(event.target.value) || 0;
      await getData(false);
    };

    const getData = async (isFirstTime: boolean) => {
      loading.value = true;

      await updateQueries();
      await getProducts(
        name.value,
        branchId.value > 0 ? branchId.value : null,
        productCategoryId.value > 0 ? productCategoryId.value : null,
        isDelivery.value,
        isEnabled.value,
        sort.value,
        pageNumber.value,
        pageSize.value
      );

      if (isFirstTime) {
        await getBranches(null, null, null, "id,asc", 1, 1000);
        await getProductCategories(null, "id,asc", 1, 1000);
      }

      reinitializeComponents();

      loading.value = false;
    };

    const sortProducts = async (order) => {
      if (order) {
        sort.value = `${order.columnName},${order.order}`;
        await getData(false);
      }
    };

    const updatePageSize = async (sizePerPage) => {
      pageSize.value = sizePerPage;
      await getData(false);
    };

    const updatePageNumber = async (numberOfPage) => {
      pageNumber.value = numberOfPage;
      await getData(false);
    };

    onMounted(async () => {
      setCurrentPageBreadcrumbs("sea.pages.products.title", [
        "sea.aside.categories.product.title",
      ]);
      await getData(true);
    });

    return {
      I18nUtils,
      UserUtils,
      DateTimeUtils,
      name,
      branchId,
      onBranchChange,
      productCategoryId,
      onProductCategoryChange,
      isDelivery,
      onIsDeliveryChange,
      isEnabled,
      onIsEnabledChange,
      tableHeaders,
      getData,
      loading,
      productsRef,
      productsTotalRef,
      productsPageNumberRef,
      productsPageSizeRef,
      sortProducts,
      updatePageSize,
      updatePageNumber,
      branchesRef,
      productCategoriesRef,
    };
  },
});
